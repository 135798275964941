<template>
    <v-container class="login-container">
        <v-row justify="center">
            <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-12">
                    <v-card-title class="primary">
                        <span class="headline">后台登录</span>
                    </v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="login">
                            <v-text-field v-model="username" label="用户名"></v-text-field>
                            <v-text-field v-model="password" label="密码" type="password"></v-text-field>
                            <v-btn color="primary" type="submit">登录</v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import api from "../../api/api";
export default {
    data() {
        return {
            username: '',
            password: ''
        };
    },
    mounted() {
        this.checkPhone();
    },
    methods: {
        login() {
            if(this.isMobile()) {
                confirm('建议使用PC端进行后端管理系统的访问!');
                return;
            }
            // 这里可以添加登录逻辑
            console.log('用户名:', this.username);
            console.log('密码:', this.password);
            api.get('/manager/login?account=' + this.username + '&&password=' + this.password).then(response => {
                console.log(response);
                if (response.status == 200) {
                    if (response.data.code == 5001) {
                        confirm('登录失败');
                    } else if (response.data.code == 200) {
                        confirm('登录成功');
                        sessionStorage.removeItem('key');
                        sessionStorage.setItem('key', response.data.data);
                        this.$router.push('/admin');
                    }
                } else {
                    confirm('登录失败');
                }
            })
        },
        checkPhone() {
            console.log(this.isMobile())
            if (this.isMobile()) {
                confirm('建议使用PC端进行后端管理系统的访问!');
                window.location.replace('about:blank');
            }
        },
        isMobile() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            // Windows Phone 必须在第一位，因为 IE11 的 UA 中也含有 Phone
            return /Windows Phone|SymbianOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Android/.test(userAgent);
        }
    }
};
</script>

<style scoped>
.primary {
    background-color: #1976d2;
    color: #fff;
}
</style>